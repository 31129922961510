import { checkAccess } from '@/mixins/permissions'

export const main = () => [
  {
    id: 1,
    icon: 'mdi-card-account-details-outline',
    key: 'sailor',
    text: '',
    path: '/',
    name: 'home',
    checkAccess: checkAccess('menuItem-sailor')
  }
]
