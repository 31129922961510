<template lang="pug">
v-list(v-if="userNotification").p-0
  v-list-item(
    v-for="(item, index) in menu"
    :key="index"
    :disabled="item.disabled"
    active-class="active").d-flex.align-start.flex-column.my-1.pa-0

    div(v-if="item.items.filter(el => el.checkAccess).length").overline.pl-2 {{ item.key ? $t(item.key) : item.text }}
    v-list(v-if="item.items.filter(el => el.checkAccess).length").px-0.w-full
      v-list-item(
        v-for="(item2, index2) in item.items.filter(el => el.checkAccess)"
        :key="index2"
        :input-value="item2.value"
        :to="item2.path"
        :exact="item2.exact"
        :disabled="item2.disabled"
        :class="{ 'activeListItem': item2.key === 'sailor' && checkActiveItem  }"
        active-class="activeListItem").my-1.vs-sidebar--item.mx-4
        div(v-if="item2.hasOwnProperty('checkAccess') ? item2.checkAccess : false").d-flex.align-center.w-100.px-2
          v-tooltip(:disabled="showFullMenu" right)
            template(#activator='{ on, attrs }')
              v-list-item-icon(v-bind='attrs' v-on='on').mr-5.p-0
                v-icon(color="#75869b") {{ item2.icon }}
            span {{ item2.key ? $t(item2.key) : item2.text }}
          v-list-item-content(v-if="showFullMenu") {{ item2.key ? $t(item2.key) : item2.text }}
          div(v-if="showFullMenu && item2.hasOwnProperty('notify') && !!item2.notify(userNotification)")
            div.con-vs-chip.ml-auto.vs-chip-success.con-color.fs-12
              span.text-chip.white--text {{item2.notify(userNotification)}}
</template>
<script>
import { mapState } from 'vuex'
import { settings, admin, documents, statements, main } from './MenuSection'

export default {
  data () {
    return {
      menu: []
    }
  },
  computed: {
    ...mapState({
      version: state => state.main.version,
      userNotification: state => state.main.userNotification,
      activePage: state => state.main.activePage
    }),
    checkActiveItem () {
      return ['new-sailor'].includes(this.$route.name) || this.$route.matched.some(el => el.name === 'sailor')
    },
    showFullMenu () {
      return this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl
    }
  },
  created () {
    this.updateListMenu(this.userNotification)
  },
  methods: {
    async updateListMenu (notify) {
      this.menu.push({ icon: 'mdi-home-variant-outline', key: 'main', text: '', path: '', items: [...main()] })
      this.menu.push({ icon: 'mdi-folder-open-outline', key: 'statements', text: '', path: '', items: [...statements(notify)] })
      this.menu.push({ icon: 'mdi-file-document-multiple-outline', key: 'documents', text: '', path: '/', items: [...documents(notify)] })
      this.menu.push({ icon: 'mdi-lock-outline', key: 'admin', text: '', path: '/', items: [...admin(notify)] })
      this.menu.push({ icon: 'mdi-cog-outline', key: 'settings', text: '', path: '/', items: [...settings()] })
    }
  }
}
</script>

<style lang="sass">
  .activeListItem
    background: linear-gradient(130deg,#4e8cff,rgba(137,173,240,.7490196078431373))
    box-shadow: 0 0 10px 1px #4e8cff
    margin: 10px 0
    border-radius: 5px
    i
      color: white !important
    div
      color: white
</style>
