import { checkAccess } from '@/mixins/permissions'

export const admin = () => [
  {
    id: 1,
    icon: 'mdi-chart-donut-variant',
    key: 'report',
    text: '',
    path: { name: 'report' },
    checkAccess: checkAccess('menuItem-report')
  },
  {
    id: 2,
    icon: 'mdi-clipboard-text-clock-outline',
    key: 'history',
    text: '',
    path: '/history',
    checkAccess: checkAccess('menuItem-userHistory') || checkAccess('menuItem-crewHistory')
  },
  {
    id: 3,
    icon: 'mdi-folder-key-outline',
    key: 'backOffice',
    text: '',
    path: '/back-office',
    checkAccess: checkAccess('menuItem-backOffice')
  },
  {
    id: 4,
    icon: 'mdi-cash-100',
    key: 'model-PriceForPosition',
    text: '',
    path: { name: 'backoffice-offline-rate', params: { typePrice: 'Offline' } },
    checkAccess: checkAccess('menuItem-backOffice')
  },
  {
    id: 5,
    icon: 'mdi-briefcase-eye-outline',
    key: 'contractInProcessing',
    text: '',
    path: '/crewing-manager-statement-from-sailor',
    notify: (notify) => notify.manager_sailor_statement,
    checkAccess: checkAccess('menuItem-crewingManagerStatementFromSailor')
  },
  {
    id: 6,
    icon: 'mdi-briefcase-eye-outline',
    key: 'statistics-verification',
    text: '',
    path: { name: 'statistics-verification' },
    checkAccess: checkAccess('menuItem-statistics-verification')
  }
]
