import { checkAccess } from '@/mixins/permissions'

export const settings = () => [
  {
    id: 1,
    icon: 'mdi-account-plus-outline',
    key: 'registration',
    text: '',
    path: '/registration',
    checkAccess: checkAccess('menuItem-newUser')
  },
  {
    id: 2,
    icon: 'mdi-account-group-outline',
    key: 'accountManagement',
    text: '',
    path: { name: 'account-management' },
    checkAccess: checkAccess('accountManagement', 'view')
  },
  {
    id: 3,
    icon: 'mdi-book-open-variant',
    key: 'directory',
    text: '',
    path: '/directory/address',
    checkAccess: checkAccess('admin')
  },
  {
    id: 4,
    icon: 'mdi-cog-outline',
    key: 'settings',
    text: '',
    path: { name: 'settings' },
    checkAccess: true
  }
]
