import { checkAccess } from '@/mixins/permissions'
export const statements = () => [
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'statementSQC',
    text: '',
    path: {
      name: 'statement-sqc',
      query: { page: '1', page_size: '10', status_document: '24' }
    },
    notify: (notify) => notify.statement_sqc_approved + notify.statement_sqc_processing + notify.statement_sqc_from_personal_cabinet,
    checkAccess: checkAccess('menuItem-statementSQC')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'recordBookStatement',
    text: '',
    path: { name: 'srbAll' },
    notify: (notify) => notify.statement_service_record,
    checkAccess: checkAccess('tab-statementServiceRecordBook')
  },
  // {
  //   icon: 'mdi-file-document-edit-outline',
  //   key: 'recordBookStatementISC',
  //   text: '',
  //   path: { name: 'statements-service-record-book-isc' },
  //   notify: (notify) => notify.statement_service_record_isc,
  //   checkAccess: checkAccess('tab-statementServiceRecordBookISC')
  // },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'etiStatementReport',
    text: '',
    path: '/eti-statements',
    notify: (notify) => notify.statement_service_record_isc,
    checkAccess: checkAccess('menuItem-statementETI')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'advanceTrainingStatement',
    text: '',
    path: '/advance-training-statements',
    checkAccess: checkAccess('menuItem-statementAdvanceTraining')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'sailorPassportStatements',
    text: '',
    path: { name: 'sailor-passport-statements' },
    checkAccess: checkAccess('menuItem-statementSP')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'nostrificationStatements',
    text: '',
    path: { name: 'nostrification' },
    checkAccess: checkAccess('menuItem-nostrification')
  },
  // {
  //   icon: 'mdi-file-document-edit-outline',
  //   key: 'processingDoc',
  //   text: '',
  //   path: '/processing-documents'
  //   checkAccess: checkAccess('menuItem-postVerificationDocuments')
  // },

  {
    icon: 'mdi-file-document-edit-outline',
    key: 'etiPayments',
    text: '',
    path: { name: 'eti-payments' },
    checkAccess: checkAccess('menuItem-etiPayments')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'verificationAcc',
    text: '',
    path: { name: 'new-accounts' },
    notify: (notify) => notify.user_to_verification,
    checkAccess: checkAccess('menuItem-verificationAccount')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'newStatementOnCreateAccountISC',
    text: '',
    path: { name: 'statement-isc' },
    checkAccess: checkAccess('menuItem-statementISC')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'packageStatementISC',
    text: '',
    path: { name: 'isc-package-statement' },
    checkAccess: checkAccess('menuItem-packageStatementISC')
  },
  {
    icon: 'mdi-file-document-edit-outline',
    key: 'packageQualificationStatements',
    text: '',
    path: { name: 'package-qualification-statement' },
    checkAccess: checkAccess('menuItem-qualificationPackageStatement')
  },
  {
    icon: 'mdi-file-document-outline',
    key: 'crewingVerification.statement',
    text: '',
    path: { name: 'crewing-verification' },
    checkAccess: checkAccess('crewingVerification', 'view')
  },
  {
    icon: 'mdi-file-document-outline',
    key: 'crewingStatement',
    text: '',
    path: { name: 'crewing-statement' },
    checkAccess: checkAccess('menuItem-backOffice')
  },
  {
    icon: 'mdi-file-document-outline',
    key: 'clarificationOfDataStatement',
    text: '',
    notify: (notify) => notify.statement_clarification,
    path: { name: 'clarification-of-data' },
    checkAccess: checkAccess('backOffice')
  }
]
