import { checkAccess } from '@/mixins/permissions'

export const documents = () => [
  {
    id: 1,
    icon: 'mdi-file-sign',
    key: 'documentsToSign',
    text: '',
    path: { name: 'documents-for-signing' },
    notify: (notify) => notify.document_to_sign,
    checkAccess: checkAccess('menuItem-documentsForSigning')
  },
  {
    id: 2,
    icon: 'mdi-file-document-outline',
    key: 'documentsVerification',
    text: '',
    path: { name: 'verification' },
    checkAccess: checkAccess('menuItem-documentsVerification')
  },
  {
    id: 3,
    icon: 'mdi-file-document-outline',
    key: 'sailorPhotoVerification',
    text: '',
    path: { name: 'sailor-photo-verification' },
    checkAccess: checkAccess('main-verifySailorPhoto')
  },
  // {
  //   id: 4,
  //   icon: 'mdi-file-document-outline',
  //   key: 'scanCopyVerification',
  //   text: '',
  //   path: '/verification-document-photos',
  //   checkAccess: checkAccess('verificationDocumentPhotos', 'view')
  // },
  {
    id: 4,
    icon: 'mdi-file-document-outline',
    key: 'digitalizationDocumentSc',
    text: '',
    path: { name: 'digitalization-document-sc' },
    checkAccess: checkAccess('verificationDocumentPhotos', 'view')
  },
  {
    id: 5,
    icon: 'mdi-file-document-outline',
    key: 'digitalizationDocumentIsc',
    text: '',
    path: { name: 'digitalization-document-isc' },
    checkAccess: checkAccess('verificationDocumentPhotos', 'view')
  },
  {
    id: 6,
    icon: 'mdi-file-document-remove-outline',
    key: 'signFailedDocuments',
    text: '',
    notify: (notify) => notify.unsigned_documents,
    path: { name: 'sign-failed-documents' },
    checkAccess: checkAccess('menuItem-signFailedDocuments')
  }
]
